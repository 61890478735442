import React, { useState } from 'react';
import { PaletteMode } from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import Image from "next/image";
import { ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import CancelIcon from '@mui/icons-material/Cancel';
import CableIcon from '@mui/icons-material/Cable';
import WalletIcon from '@mui/icons-material/Wallet';
import Link from '@mui/material/Link';
import { addTokenToWallet } from '@/components/addToken';
import { useSnackbar } from 'notistack';
import { useAccount } from 'wagmi';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'


interface AppAppBarProps {
    mode: PaletteMode;
    toggleColorMode: () => void;
}





function AppAppBar({ mode, toggleColorMode }: AppAppBarProps) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { address,isConnected, chain } = useAccount();

    const [openAddTokenManuell, setOpenAddTokenManuell] = React.useState(false);

    const { openConnectModal } = useConnectModal();

    const AddtokenModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '1200px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        boxSizing: 'border-box',
    };

    const smallScreenStyles = {
        '@media (max-width: 600px)': {
            width: '90%',
            p: 2,
        },
    };

    const handleAddTokenManuellOpen = () => {
        console.log('Opening modal');
        setOpenAddTokenManuell(true);
    };

    const handleAddTokenManuellClose = () => {
        console.log('Closing modal');
        setOpenAddTokenManuell(false);
    };

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const scrollToSection = (sectionId: string) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
            setOpen(false);
        }
    };

    const handleLotteryClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddToken = async (tokenType: string) => {
        if (!address) {
            enqueueSnackbar('Wallet is not connected', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
            return;
        }

        if (chain && chain.id !== 14) {
            enqueueSnackbar('Incorrect network. Please connect to the network with ChainID 14 (FlareNetworks).', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
            return;
        }

        try {
            await addTokenToWallet(tokenType);
            enqueueSnackbar(`${tokenType.toUpperCase()} Token added to wallet.`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        } catch (error) {
            enqueueSnackbar(`Error adding token to wallet`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
            console.error('Error adding token to wallet:', error);
        }
    };


    return (
        <div>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '999px',
                            bgcolor:
                                theme.palette.mode === 'light'
                                    ? 'rgba(255, 255, 255, 0.4)'
                                    : 'rgba(0, 0, 0, 0.4)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                    : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                        })}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-0px',
                                px: 0,
                            }}
                        >
                            <Image
                                src="/DaXLogo.png"
                                alt="DaX Logo"
                                width={128}
                                height={51}
                                priority
                                style={{
                                    marginRight: '20px'
                                }}
                            />



                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <MenuList sx={{ display: 'flex', gap: '20px' }}>
                                    <MenuItem
                                        onClick={() => scrollToSection('wilcome')}
                                        sx={{ py: '6px', px: '12px' }}
                                    >
                                        <Typography variant="body2" color="text.primary">
                                            Home
                                        </Typography>
                                    </MenuItem>

                                    <MenuItem
                                        onClick={() => scrollToSection('roadmap')}
                                        sx={{ py: '6px', px: '12px' }}
                                    >
                                        <Typography variant="body2" color="text.primary">
                                            Roadmap
                                        </Typography>
                                    </MenuItem>

                                    <MenuItem
                                        onClick={() => scrollToSection('community')}
                                        sx={{ py: '6px', px: '12px' }}
                                    >
                                        <Typography variant="body2" color="text.primary">
                                            Community
                                        </Typography>
                                    </MenuItem>

                                    <MenuItem
                                        onClick={handleLotteryClick}
                                        sx={{ py: '6px', px: '12px' }}
                                    >
                                        <Typography variant="body2" color="text.primary">
                                            Lottery
                                        </Typography>
                                    </MenuItem>
                                    <Menu
                                        id="lottery-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'lottery-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleClose}>
                                            <Link href="/lottery/" style={{ textDecoration: 'none' }}>
                                                Lottery Entry
                                            </Link>
                                        </MenuItem>
                                        <MenuItem onClick={handleClose}>
                                            <Link href="/lottery-nfts" style={{ textDecoration: 'none' }}>
                                                Lottery NFTs
                                            </Link>
                                        </MenuItem>
                                        <MenuItem onClick={handleClose}>
                                            <Link href="/lottery/guide/" style={{ textDecoration: 'none' }}>
                                                Lottery Guide
                                            </Link>
                                        </MenuItem>
                                        <MenuItem onClick={handleClose}>
                                            <Link href="/lottery/mechanisms/" style={{ textDecoration: 'none' }}>
                                                Lottery Mechanisms
                                            </Link>
                                        </MenuItem>
                                    </Menu>
                                </MenuList>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                gap: 0.5,
                                alignItems: 'center',
                            }}
                        >
                            <ConnectButton.Custom>
                                {({
                                      account,
                                      chain,
                                      openAccountModal,
                                      openChainModal,
                                      openConnectModal,
                                      authenticationStatus,
                                      mounted,
                                  }) => {
                                    const ready = mounted && authenticationStatus !== 'loading';
                                    const connected =
                                        ready &&
                                        account &&
                                        chain &&
                                        (!authenticationStatus ||
                                            authenticationStatus === 'authenticated');

                                    return (
                                        <>
                                            {(() => {
                                                if (!connected) {
                                                    return (
                                                        <Button variant="outlined" size="medium" onClick={openConnectModal} startIcon={<CableIcon />}>
                                                            Connect Wallet
                                                        </Button>
                                                    );
                                                }

                                                if (chain.unsupported) {
                                                    return (
                                                        <Button variant="outlined" size="medium" color="error" onClick={openChainModal} startIcon={<CancelIcon />}>
                                                            Wrong Network
                                                        </Button>
                                                    );
                                                }

                                                return (
                                                    <Button variant="outlined" size="medium" onClick={openAccountModal} startIcon={<WalletIcon />}>
                                                        {account.displayName}
                                                    </Button>
                                                );
                                            })()}
                                        </>
                                    );
                                }}
                            </ConnectButton.Custom>


                            <IconButton
                                onClick={handleAddTokenManuellOpen}
                                title={'Add Tokens to Wallet'}
                                sx={{
                                    color: (theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
                                }}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>

                            <Modal
                                open={openAddTokenManuell}
                                onClose={handleAddTokenManuellClose}
                                aria-labelledby="modal-add-token-title"
                                aria-describedby="modal-add-token-description"
                            >
                                <Box sx={{ ...AddtokenModalStyle, ...smallScreenStyles, position: 'relative', p: 3, textAlign: 'center' }}>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleAddTokenManuellClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography id="modal-add-token-title" variant="h2" component="h2">
                                        Add Token to Wallet
                                    </Typography>

                                    <Typography id="modal-add-token-description" sx={{ mt: 2, fontSize: '1.2rem' }}>
                                        You can manually add the DAX token to your wallet here or use the automatic function we provide.
                                    </Typography>

                                    <Box display="flex" justifyContent="center" mt={2}>
                                        {!isConnected ? (
                                            <Box>
                                                <Typography sx={{ mb: 2, fontSize: '1.2rem' }}>
                                                    To add tokens via a function, you need to connect your wallet.
                                                </Typography>
                                                <Button variant="contained" color="secondary" size="large" onClick={openConnectModal}>
                                                    Connect Wallet
                                                </Button>
                                            </Box>
                                        ) : (
                                            <>
                                                <Button variant="contained" color="secondary" size="large" onClick={() => handleAddToken('dax')} sx={{ mr: 2 }}>
                                                    Add DAX Token to Wallet
                                                </Button>
                                                <Button variant="contained" color="secondary" size="large" onClick={() => handleAddToken('wflr')}>
                                                    Add WFLR Token to Wallet
                                                </Button>
                                            </>
                                        )}
                                    </Box>

                                    <Typography id="modal-add-token-contract-address" sx={{ mt: 2, fontSize: '1.2rem', wordWrap: 'break-word' }}>
                                        <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>DAX contract:</Typography> 0xDE373AE127A11E756A9D1cc7743816928B239283
                                    </Typography>
                                    <Typography id="modal-add-token-token-decimal" sx={{ mt: 2, fontSize: '1.2rem' }}>
                                        <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Decimals:</Typography> 18
                                    </Typography>
                                    <Typography id="modal-add-token-token-network" sx={{ mt: 2, fontSize: '1.2rem' }}>
                                        <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Network:</Typography> Flare Mainnet
                                    </Typography>
                                </Box>
                            </Modal>



                            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode}/>
                        </Box>
                        <Box sx={{ display: { sm: '', md: 'none' } }}>
                            <ConnectButton.Custom>
                                {({
                                      account,
                                      chain,
                                      openAccountModal,
                                      openChainModal,
                                      openConnectModal,
                                      authenticationStatus,
                                      mounted,
                                  }) => {
                                    const ready = mounted && authenticationStatus !== 'loading';
                                    const connected =
                                        ready &&
                                        account &&
                                        chain &&
                                        (!authenticationStatus ||
                                            authenticationStatus === 'authenticated');

                                    return (
                                        <>
                                            {(() => {
                                                if (!connected) {
                                                    return (
                                                        <Button variant="outlined" size="medium" onClick={openConnectModal}>
                                                           Connect
                                                        </Button>
                                                    );
                                                }

                                                if (chain.unsupported) {
                                                    return (
                                                        <Button variant="outlined" size="medium" color="error" onClick={openChainModal} startIcon={<CancelIcon />}>
                                                            Wrong Network
                                                        </Button>
                                                    );
                                                }

                                                return (
                                                    <IconButton
                                                        onClick={openAccountModal}
                                                        title={'Wallet'}
                                                        sx={{
                                                            color: (theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
                                                        }}
                                                    >
                                                        <WalletIcon />
                                                    </IconButton>
                                                );
                                            })()}
                                        </>
                                    );
                                }}
                            </ConnectButton.Custom>

                            <IconButton
                                onClick={handleAddTokenManuellOpen}
                                title={'Add Tokens to Wallet'}
                                sx={{
                                    color: (theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
                                }}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>

                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{ minWidth: '30px', p: '4px' }}
                            >
                                <MenuIcon />
                            </Button>
                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: '60dvw',
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'end',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                                    </Box>

                                    <MenuItem onClick={() => scrollToSection('home')}>
                                        Home
                                    </MenuItem>

                                    <MenuItem onClick={() => scrollToSection('roadmap')}>
                                        Roadmap
                                    </MenuItem>

                                    <MenuItem onClick={() => scrollToSection('community')}>Community</MenuItem>

                                    <MenuItem component={Link} href="/lottery">
                                        <Typography variant="body2" color="text.primary">
                                            Lottery Entry
                                        </Typography>
                                    </MenuItem>

                                    <MenuItem component={Link} href="/lottery-nfts">
                                        <Typography variant="body2" color="text.primary">
                                            Lottery NFTs
                                        </Typography>
                                    </MenuItem>

                                    <MenuItem component={Link} href="/lottery/guide/">
                                        <Typography variant="body2" color="text.primary">
                                           Lottery Guide
                                        </Typography>
                                    </MenuItem>

                                    <MenuItem component={Link} href="/lottery/mechanisms/">
                                        <Typography variant="body2" color="text.primary">
                                            Lottery Mechanisms
                                        </Typography>
                                    </MenuItem>

                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}

export default AppAppBar;
import React, { useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';

interface AnimatedNumberProps {
    value: number;
}

const formatNumber = (number: number) => {
    return new Intl.NumberFormat('de-DE').format(number);
};

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({ value }) => {
    const [props, api] = useSpring(() => ({ number: 0 }));

    useEffect(() => {
        api.start({ number: value, config: { duration: 3000 } });
    }, [value, api]);

    return <animated.span>{props.number.to(n => formatNumber(Number(n.toFixed(0))))}</animated.span>;
};

export default AnimatedNumber;
import { Box, Typography, Container, Grid, useTheme, Paper, Button} from '@mui/material';
import React from "react";
import Image from 'next/image';

import Carousel from 'react-material-ui-carousel'

const CorporatePartners = () => {
    const theme = useTheme();

    const items = [
        {
            images: [
                {
                    src: "/Floor-Sweeper.svg",
                    title: "Floor-Sweeper",
                    description: "",
                    link: "https://x.com/Floor_Sweeper_",
                    style: { filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'invert(0)', width: '100&' }
                },
                {
                    src: "/FTSO.Best.svg",
                    title: "FTSO.Best",
                    description: "",
                    link: "https://ftso.best",
                    style: { width: '100&' }
                }
            ]
        },
        {
            images: [
                {
                    src: "/Floor-Sweeper.svg",
                    title: "Floor-Sweeper",
                    description: "",
                    link: "https://x.com/Floor_Sweeper_",
                    style: { filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'invert(0)', width: '100&' }
                },
                {
                    src: "/FTSO.Best.svg",
                    title: "FTSO.Best",
                    description: "",
                    link: "https://ftso.best",
                    style: { width: '100&' }
                }
            ]
        }
    ];

    const Item = (props: any) => {
        return (
            <Paper>
                <Grid container spacing={2}>
                    {props.item.images.map((image: any, index: number) => (
                        <Grid item xs={6} key={index}>
                            <Image
                                src={image.src}
                                alt={image.title}
                                layout="responsive"
                                width={300}
                                height={300}
                                style={image.style}
                            />
                            <Typography variant="h6">{image.title}</Typography>
                            <Typography>{image.description}</Typography>
                            <Button href={image.link} variant="contained" color="primary" target="_blank">
                                Go to Project
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        );
    };

    return (
        <Box
            id="corporate"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
            }}
        >
            <>
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4" color="text.primary">
                        Corporate Partners
                    </Typography>

                    <Box sx={{ width: '100%' }}>
                        <Carousel>
                            {items.map((item, i) => <Item key={i} item={item} />)}
                        </Carousel>
                    </Box>
                </Box>
            </Container>
            </>
        </Box>
    );
};

export default CorporatePartners;
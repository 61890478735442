"use client"
import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Grid, Button, Tooltip, IconButton, ButtonGroup, Collapse } from '@mui/material';
import Web3 from 'web3';
import Divider from "@mui/material/Divider";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ClientOnly from '@/app/clientOnly';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Image from 'next/image';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import {formatUnits} from "ethers";

interface ApiLastBurnResponse {
    time: string;
    burnsum: string;
}
interface CirculatingSupplyResponse {
    result: string; // Assuming result is a string based on the error
}

interface CirculatingSupplyState {
    result: string | null; // Assuming state should hold a string or null
}

const Wilcome = () => {
    const [totalSupply, setTotalSupply] = useState(null);
    const [totalBurnSupply, setTotalBurnSupply] = useState(null);
    const [totalBurnSupplyProzent, setTotalBurnSupplyProzent] = useState(0);

    const [symbol, setSymbol] = useState(null);

    const [name, setName] = useState(null);
    const [burnRate, setBurnRate] = useState(null);
    const [decimals, setDecimals] = useState(null);

    const [openInfo, setOpenInfo] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const [burnData, setBurnData] = useState<ApiLastBurnResponse | null>(null);
    const [circulatingSupply, setCirculatingSupply] = useState<CirculatingSupplyState | null>(null);

    const [lotteryStarkingVolume, setLotteryStarkingVolume] = useState<any>(0);
    const [countLotteryActiveTickets, setCountLotteryActiveTickets] = useState<any>(0);

    const [poolVolume, setPoolVolume] = useState<number>(0);

    const theme = useTheme();


    const fetchPoolVolume = async () => {
        try {
            const response = await fetch('/api/lottery-get-pool-volume/');
            if (!response.ok) {
                throw new Error('Network response was not OK');
            }
            const data = await response.json();
            return data[0].pool_volume;
        } catch (error) {
            console.error('Failed to get Pool value:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const poolVolume = await fetchPoolVolume();
                setPoolVolume(poolVolume);
            } catch (error) {
                console.error('Error fetching pool volume:', error);
            }
        };

        fetchData();
    }, []);


    const handleToggle = () => {
        setOpenInfo(!openInfo);
    };

    const { enqueueSnackbar } = useSnackbar();

    const contract_address = '0xDE373AE127A11E756A9D1cc7743816928B239283';
    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(contract_address);
            enqueueSnackbar('Contract Address copied to clipboard', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            });
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [lastBurnResponse, circulatingSupplyResponse] = await Promise.all([
                    fetch('/api/last-burn/'),
                    fetch('/api/circulating-supply/')
                ]);

                if (!lastBurnResponse.ok || !circulatingSupplyResponse.ok) {
                    throw new Error('Network response was not ok');
                }

                const lastBurnData: ApiLastBurnResponse = await lastBurnResponse.json();
                const circulatingSupplyData: CirculatingSupplyResponse = await circulatingSupplyResponse.json();
                setBurnData(lastBurnData);
                setCirculatingSupply(circulatingSupplyData); // Ensure type matches the state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        (async () => {
            await fetchData();
        })();
    }, []);

    const formatSum = (burnsum:  any) => {
        if (typeof burnsum !== 'string') return '';
        const number = parseFloat(burnsum.replace('.', ',')); // Komma in Punkt umwandeln und in Gleitkommazahl konvertieren
        return number.toLocaleString('de-DE', { style: 'currency', currency: 'DAX', maximumFractionDigits: 0, useGrouping: true });
    };

    const formatDate = (dateString: string): string => {
        const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize); // Check on resize

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const getHealthyRPC = async () => {
            const healthCheckURL1 = 'https://flrrpc.ftso.best/ext/health';
            const healthCheckURL2 = 'https://flare.solidifi.app/ext/health';
            const healthCheckURL3 = 'https://flare-api.flare.network/ext/health';
            const rpcURL1 = 'https://flarerpc.dax.best/ext/C/rpc';
            const rpcURL2 = 'https://flare.solidifi.app/ext/C/rpc';
            const rpcURL3 = 'https://flare-api.flare.network/ext/C/rpc';

            // Funktion zum Überprüfen des Gesundheitsstatus
            async function checkHealth(url: any) {
                try {
                    const response = await fetch(url);
                    const data = await response.json();
                    return data.healthy;
                } catch (error) {
                    console.error('Fehler beim Überprüfen des Gesundheitsstatus:', error);
                    return false;
                }
            }

            const isHealthy1 = await checkHealth(healthCheckURL1);
            if (isHealthy1) {
                return rpcURL1;
            }

            const isHealthy2 = await checkHealth(healthCheckURL2);
            if (isHealthy2) {
                return rpcURL2;
            }

            const isHealthy3 = await checkHealth(healthCheckURL3);
            if (isHealthy3) {
                return rpcURL3;
            }

            return rpcURL3; // Falls keiner der RPCs gesund ist, wird der letzte verwendet
        }

        const initializeWeb3 = async () => {
            try {
                const healthyRPC = await getHealthyRPC();
                const web3 = new Web3(healthyRPC);
                console.log('Web3 initialisiert mit:', healthyRPC);

                const contractAddress = '0xDE373AE127A11E756A9D1cc7743816928B239283';
                const lotteryContractAddress = '0x9294B701C90Df3c0C49aad6cc2b5C4e1d45F20D2';
                const contractABI = [
                    {
                        "type": "function",
                        "stateMutability": "view",
                        "outputs": [
                            {
                                "type": "uint256",
                                "name": "",
                                "internalType": "uint256"
                            }
                        ],
                        "name": "totalSupply",
                        "inputs": []
                    },
                    {
                        "type": "function",
                        "stateMutability": "view",
                        "outputs": [
                            {
                                "type": "string",
                                "name": "",
                                "internalType": "string"
                            }
                        ],
                        "name": "symbol",
                        "inputs": []
                    },
                    {
                        "type": "function",
                        "stateMutability": "view",
                        "outputs": [
                            {
                                "type": "string",
                                "name": "",
                                "internalType": "string"
                            }
                        ],
                        "name": "name",
                        "inputs": []
                    },
                    {
                        "type": "function",
                        "stateMutability": "view",
                        "outputs": [
                            {
                                "type": "uint256",
                                "name": "",
                                "internalType": "uint256"
                            }
                        ],
                        "name": "burnRate",
                        "inputs": []
                    },
                    {
                        "type": "function",
                        "stateMutability": "view",
                        "outputs": [
                            {
                                "type": "uint256",
                                "name": "",
                                "internalType": "uint256"
                            }
                        ],
                        "name": "decimals",
                        "inputs": []
                    },
                    {
                        "type": "function",
                        "stateMutability": "view",
                        "outputs": [
                            {
                                "type": "uint256",
                                "name": "",
                                "internalType": "uint256"
                            }
                        ],
                        "name": "balanceOf",
                        "inputs": [
                            {
                                "type": "address",
                                "name": "account",
                                "internalType": "address"
                            }
                        ]
                    },
                ];
                const lotteryContractABI = [
                    {
                        "type": "function",
                        "stateMutability": "view",
                        "outputs": [
                            {
                                "type": "uint256",
                                "name": "",
                                "internalType": "uint256"
                            }
                        ],
                        "name": "countActivTickets",
                        "inputs": []
                    },
                    {
                        "type": "function",
                        "stateMutability": "view",
                        "outputs": [
                            {
                                "type": "uint256[]",
                                "name": "",
                                "internalType": "uint256[]"
                            }
                        ],
                        "name": "getCurrentWinningNumbers",
                        "inputs": []
                    },
                ];

                const contractInstance = new web3.eth.Contract(contractABI, contractAddress);
                const lotteryContractInstance = new web3.eth.Contract(lotteryContractABI, lotteryContractAddress);


                const formatNumber = (number: any) => {
                    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                };
                const removeDecimals = (number: any, decimals: any) => {
                    const divider = BigInt(10) ** BigInt(decimals);
                    return number / divider;
                };

                const fetchData = async () => {
                    try {
                        const Supply = await contractInstance.methods.totalSupply().call();
                        const totalSupplyWithoutDecimals = removeDecimals(Supply, 18);
                        const totalSupplyFormatted = formatNumber(totalSupplyWithoutDecimals);
                        setTotalSupply(totalSupplyFormatted.toString());


                        const countTickets: any = await lotteryContractInstance.methods.countActivTickets().call();
                        setCountLotteryActiveTickets(countTickets.toString());

                        const LotteryStarkingVolume: any = await contractInstance.methods.balanceOf(lotteryContractAddress).call();

                        const lotteryStarkingVolumeInEther = formatUnits(LotteryStarkingVolume, 'ether');

                        // Format the Ether value
                        const formattedVolume = Number(lotteryStarkingVolumeInEther).toLocaleString(undefined, {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                        });
                        setLotteryStarkingVolume(formattedVolume);
                        const startTotalSupply = 50000000000; // Gesamtangebot
                        const burnedSupply = startTotalSupply - Number(totalSupplyWithoutDecimals);
                        const burnedSupplyFormatted = formatNumber(burnedSupply);
                        const burnedSupplyPercent = (burnedSupply / startTotalSupply) * 100;
                        const burnedSupplyPercentFormatted = burnedSupplyPercent.toFixed(2); // Optional: auf 2 Dezimalstellen runden

                        setTotalBurnSupply(burnedSupplyFormatted.toString());
                        setTotalBurnSupplyProzent(Number(burnedSupplyPercentFormatted));

                        const Symbol: any = await contractInstance.methods.symbol().call();
                        setSymbol(Symbol.toString());

                        const Name: any = await contractInstance.methods.name().call();
                        setName(Name.toString());

                        const BurnRate: any = await contractInstance.methods.burnRate().call();
                        setBurnRate(BurnRate.toString());

                        const Decimals: any = await contractInstance.methods.decimals().call();
                        setDecimals(Decimals.toString());




                    } catch (error) {
                        console.error('Error fetching total supply:', error);
                    }
                };

                await fetchData();

            } catch (error) {
                console.error('Fehler bei der Initialisierung von Web3:', error);
            }
        }

        (async () => {
            await initializeWeb3();
        })();

        // Cleanup function
        return () => {
            // Optional: Disconnect from Web3 provider if necessary
        };
    }, []);


    return (
        <ClientOnly>
        <Box
            id="wilcome"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
            }}
        >

            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '100%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Box
                        sx={{
                            p: 2
                        }}
                    >
                        <Typography component="h1" variant="h1" color="text.primary">
                            Welcome to Flare DAX!
                        </Typography>

                        <Typography variant="body1" color="text.secondary" sx={{ py: 2 }}>
                            DAX is a Memecoin leveraging the unique potential of the Flare Network to create a balanced and secure token economy. It features a lottery system where users can stake DAX tokens for a chance to win rewards, ensuring the tokens remain out of circulation and maintaining price stability.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" sx={{ py: 2 }}>
                            Lottery draws occur biweekly via a smart contract that generates random winning numbers, with rewards distributed in WFLR to winners holding 3, 4, 5, or 6 matching numbers. The smart contract transparently manages ticket stakes and prize distribution, ensuring fairness and preventing manipulation.
                        </Typography>

                    </Box>

                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            textAlign: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>

                            <Typography variant="h6" sx={{ mx: 2, cursor: 'pointer' }} onClick={handleToggle} >
                                <ArrowDropDownIcon />The DAX memecoin project stands out from other memecoins due to several key features<ArrowDropDownIcon />
                            </Typography>

                        </Box>
                        <Collapse in={openInfo}>
                            <Typography variant="body1" sx={{ textAlign: 'left' }} color="text.secondary">
                                1. *Prevention of Pump & Dump*: DAX has measures in place to avoid the common pump and dump issues seen in other memecoins. The development team&apos;s tokens are locked for a year, and there is no immediate selling of large quantities of tokens that could crash the market.
                                <br /><br />
                                2. *Innovative Use of Flare Network*: The DAX project utilizes the Flare Network&apos;s unique features, such as FlareDrops and a liquidity pool (WFLR/DAX), to create a sustainable ecosystem. The Lotto Pool, funded by FlareDrops, adds an additional layer of value without relying on user funds.
                                <br /><br />
                                3. *Transparent and Fair Lottery System*: DAX offers a fully developed lottery (6 out of 49) where users can stake $10 worth of DAX tokens for 30 days. This staking mechanism ensures that tokens are temporarily removed from circulation, helping stabilize the market. The lottery is transparent, conducted via smart contracts, and the winnings are distributed in WFLR, protecting the DAX token from sudden sell-offs.
                                <br /><br />
                                4. *NFT Integration*: DAX incorporates NFTs that enhance lottery winnings. These NFTs are sold for $FLR and $WFLR, with 50% of the proceeds used for buy and burn, further reducing the total supply of DAX tokens.
                                <br /><br />
                                5. *Burn Rate Mechanism*: A 2% burn rate is applied to every transaction, including transfers and staking, which continually decreases the total supply of DAX tokens, creating a deflationary effect.
                                <br /><br />
                                Overall, DAX’s strategic tokenomics, use of the Flare Network, transparent lottery system, and deflationary mechanisms provide a robust and sustainable framework that addresses the common pitfalls of other memecoins.
                            </Typography>
                        </Collapse>
                    </Box>


                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 6 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                <div>
                                    <Tooltip title="Kopieren">
                                        <IconButton size="small" onClick={handleCopyClick}>
                                            <FileCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                                        {contract_address.substring(0, 4)}...{contract_address.substring(contract_address.length - 4)}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={4} container justifyContent="flex-end">
                                <ButtonGroup variant="text" color="inherit" aria-label="button group">
                                    <Button
                                        size="small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={'Dax Token - Flare Explorer'}
                                        href="https://flare-explorer.flare.network/address/0xDE373AE127A11E756A9D1cc7743816928B239283"
                                    >
                                        <Box sx={{ filter: theme.palette.mode === 'light' ? 'invert(0%)' : 'invert(100%)' }}>
                                            <Image
                                                src="/flare_icon.svg"
                                                alt="Flare Logo"
                                                width={20}
                                                height={20}
                                            />
                                        </Box>
                                    </Button>

                                    <Button
                                        size="small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={'Dax Token - BlazeSwap Swap'}
                                        href="https://app.blazeswap.xyz/swap/?inputCurrency=NAT&outputCurrency=0xDE373AE127A11E756A9D1cc7743816928B239283"
                                    >
                                        <Image
                                            src="/blazeswap_icon.svg"
                                            alt="BlazeSwap Logo"
                                            width={20}
                                            height={20}
                                        />
                                    </Button>

                                    <Button
                                        size="small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={'Dax Token - GeckoTerminal'}
                                        href="https://www.geckoterminal.com/de/flare/pools/0xb2868e9fef2b5efb4aa4853a539a6ba26177b8c3"
                                    >
                                        <Image
                                            src="/geckoterminal_icon.svg"
                                            alt="Geckoterminal Logo"
                                            width={20}
                                            height={20}
                                        />
                                    </Button>
                                    <Button
                                        size="small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={'Dax Token - DexTools'}
                                        href="https://www.dextools.io/app/en/flare/pair-explorer/0xb2868e9fef2b5efb4aa4853a539a6ba26177b8c3?t=1719418049473"
                                    >
                                        <Image
                                            src="/DEXTools.png"
                                            alt="DEXTools Logo"
                                            width={20}
                                            height={20}
                                        />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />

                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Token Name</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem',
                                            sm: '1.2rem',
                                            md: '1.5rem',
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {name !== null ? (
                                            <>{name}</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Token Symbol</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {symbol !== null ? (
                                            <>{symbol}</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Decimals</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {decimals !== null ? (
                                            <>{decimals}</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Blockchain</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>FLR Chain (14)</Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Total Supply</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {totalSupply !== null ? (
                                            <>{totalSupply} DAX</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Burn Rate</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {burnRate !== null ? (
                                            <>{burnRate}%</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%" // Optional, if you want it to be vertically centered as well
                                >
                                    <Image
                                        src="/tokenomics.png"
                                        alt="Flare Dax Tokenomics"
                                        width={250}
                                        height={250}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6}>
                                        <Box>
                                            <Typography variant="body1">Total Burned</Typography>
                                            <Typography variant="body2" sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.2rem',
                                                    md: '1.5rem',
                                                },
                                                fontFamily: 'Arial, sans-serif',
                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                fontWeight: 'bold',
                                            }}>
                                                <>
                                                    {totalBurnSupply !== null ? (
                                                        <>{totalBurnSupply} DAX</>
                                                    ) : (
                                                        <>Loading...</>
                                                    )}
                                                    </>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Total Burned in Percent</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {totalBurnSupplyProzent !== null ? (
                                                    <>{totalBurnSupplyProzent}%</>
                                                ) : (
                                                    <>Loading...</>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <Box>
                                            <Typography variant="body1">Circulating Supply</Typography>
                                            <Typography variant="body2" sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.2rem',
                                                    md: '1.5rem',
                                                },
                                                fontFamily: 'Arial, sans-serif',
                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                fontWeight: 'bold',
                                            }}>
                                                <>

                                                    {circulatingSupply !== null ? (
                                                        <>{formatSum(circulatingSupply.result)}</>
                                                    ) : (
                                                        <>Loading...</>
                                                    )}
                                                </>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Box>
                                            <Typography variant="body1">Blocked Stock</Typography>
                                            <Typography variant="body2" sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.2rem',
                                                    md: '1.5rem',
                                                },
                                                fontFamily: 'Arial, sans-serif',
                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                fontWeight: 'bold',
                                            }}>
                                                <>
                                                    2.500.000.000
                                                </>
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Last Buy and Burn</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem',
                                                sm: '1.2rem',
                                                md: '1.5rem',
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {burnData ? (
                                                    <>{formatDate(burnData.time)}</>
                                                ) : (
                                                    <p>Lade Daten...</p>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Burned</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem',
                                                sm: '1.2rem',
                                                md: '1.5rem',
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {burnData ? (
                                                    <>
                                                        {formatSum(burnData.burnsum)}
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>





                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Box>

                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6}>
                                        <Box>
                                            <Typography variant="body1">Pool Volume</Typography>
                                            <Typography variant="body2" sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.2rem',
                                                    md: '1.5rem',
                                                },
                                                fontFamily: 'Arial, sans-serif',
                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                fontWeight: 'bold',
                                            }}>
                                                <>
                                                    {Math.round(poolVolume)} WFLR
                                                </>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Total Active Lottery Tickets</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {countLotteryActiveTickets ? (
                                                    <>
                                                        {countLotteryActiveTickets}
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Total Lottery Staking Volume</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {lotteryStarkingVolume ? (
                                                    <>
                                                        {lotteryStarkingVolume} DAX
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%" // Optional, if you want it to be vertically centered as well
                                >
                                    <Image
                                        src="/lottery01.png"
                                        alt="Flare Dax Lottery"
                                        width={250}
                                        height={250}
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

            </Container>



        </Box>
        </ClientOnly>
    );
};

export default Wilcome;
import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import NotStartedIcon from '@mui/icons-material/NotStarted';
const Roadmap = () => {
    return (
        <Box
            id="roadmap"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
            }}
        >

            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4" color="text.primary">
                        Roadmap
                    </Typography>

                    <List >
                        <ListItem >
                            <ListItemText primary="Phase 1: Planning" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 2: Team Formation" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 3: Smart Contract Development (Dax Token)" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 4: Presale Creation (Website, X, Telegram, Discord)" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 5: Marketing for Presale Phase" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 6: Presale & Token Launch" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 7: Dax Lottery Development" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Phase 8: Lottery Smart Contract Development" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Phase 9: Lottery Frontend Site Development" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Phase 10: Dax Lottery Start Periode 1 (15.07.2024)" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Phase 11: Dax NFT Contract Development" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Phase 12: Node Server (FLR)" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 13: Node Server (SGB)" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Schedule">
                                    <ScheduleIcon style={{ color: 'orange' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 14: FTSO Dataprovider Development" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Schedule">
                                    <ScheduleIcon style={{ color: 'orange' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 15: FTSO Dataprovider (Check)" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Schedule">
                                    <ScheduleIcon style={{ color: 'orange' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 16: FTSO Dataprovider PRE (SGB)" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Not Started">
                                    <NotStartedIcon style={{ color: 'red' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phase 17: FTSO Dataprovider PRE (FLR)" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Not Started">
                                    <NotStartedIcon style={{ color: 'red' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Phase 18: Generate first NFT Set for Lottery" primaryTypographyProps={{ color: 'text.primary' }} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Finish">
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Box>

            </Container>



        </Box>
    );
};

export default Roadmap;
"use client"
import {Box, Typography, Container, Divider, Button} from '@mui/material';
import React, {useEffect, useState} from 'react';
import AnimatedNumber from '@/components/AnimatedNumber';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Lottery = () => {
    const [poolVolume, setPoolVolume] = useState<number>(0);

    const data = [
        { id: 0, value: 60, label: '6 correct numbers' },
        { id: 1, value: 14, label: '5 correct numbers' },
        { id: 2, value: 4, label: '4 correct numbers' },
        { id: 3, value: 2, label: '3 correct numbers' },
        { id: 4, value: 12, label: 'NFT Bonus' },
        { id: 5, value: 8, label: 'Base' },
    ];
    const COLORS = ['#6140c4', '#218fc9', '#6bdfa8', '#c395e0', '#ffe955', '#e82565'];

    const fetchPoolVolume = async () => {
        try {
            const response = await fetch('/api/lottery-get-pool-volume/');
            if (!response.ok) {
                throw new Error('Network response was not OK');
            }
            const data = await response.json();
            return data[0].pool_volume;
        } catch (error) {
            console.error('Failed to get Pool value:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const poolVolume = await fetchPoolVolume();
                setPoolVolume(poolVolume);
            } catch (error) {
                console.error('Error fetching pool volume:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Box
            id="lottery"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { xs: '100%', md: '60%' },
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h2" variant="h2" color="text.primary">
                        Lottery
                    </Typography>

                    <Typography variant="body1" color="text.secondary" sx={{ py: 2 }}>
                        Winning numbers are drawn every 15 days.
                    </Typography>

                    <Typography component="h3" variant="h3" color="text.primary">
                        Lottery Pool Volume
                    </Typography>
                    <Divider />
                    <Typography component="h4" variant="h1" color="text.primary">
                        <AnimatedNumber value={Math.round(poolVolume)} /> WFLR
                    </Typography>

                    <Divider />
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Pie
                                    data={data}
                                    dataKey="value"
                                    nameKey="label"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    label={({ name, value }) => `${value}%`}
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value) => `${value}%`} />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 2, // This adds spacing between the buttons
                        }}
                    >
                        <Button variant="contained" color="secondary" size="large" href={'/lottery/guide/'}>Lottery Guide</Button>
                        <Button variant="contained" color="secondary" size="large" href={'/lottery/mechanisms/'}>Lottery Mechanisms</Button>

                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Lottery;
